<template>
  <div class="layer-page-div">
    <div class="big-img-div" v-show="isShowBigImg" @click="onHideBigImgDiv">
      <img ref="bigImg" src="" alt=""/>
    </div>
    <div class="layer-content-div">
      <div class="text-c">设备名称：{{ manufacturerDiagramInfo.name }}</div>
      <div class="item-div poster-div">
        <div class="item-name-div">设备图片：</div>
        <div v-show="isShowAddImg">
          <label class="upload-img-label" @change="onAddImg($event)">
            <img class="cursor-el" src="../../assets/picture.png" alt="" />
            <input class="cursor-el" type="file" />
          </label>
        </div>
        <div v-show="!isShowAddImg" class="preview-img-div">
          <img class="del-img" src="../../assets/del.png" alt="" @click="onDelImg">
          <img ref="img" src="" alt="" @click="onShowBigImgDiv($event)">
        </div>
      </div>
      <div class="operate-div">
        <div class="tips-div text-c err-tips">{{ tipMsg }}</div>
        <div class="text-c">
          <input type="button" value="取消" @click="onCancel">
          <input class="save-input" type="button" value="保存" @click="onSave">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { postManufacturerDiagramEdit } from "../../common/api";

export default {
  props: {
    layerid: {
      type: String,
      default: ""
    },
    manufacturerDiagramInfo: {
      type: Object,
      default: () => {}
    },
    refreshData: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      isShowAddImg: false,
      isShowBigImg: false,
      manufacturerImg: null,
      tipMsg: ""
    }
  },
  created() {
    if(Object.prototype.hasOwnProperty.call(this.manufacturerDiagramInfo, "img")){
      this.isShowAddImg = false;
      this.$nextTick(() => {
        this.$refs.img.src = `${this.$imgBaseURL}/${this.manufacturerDiagramInfo.img}`;
      });
    }else{
      this.isShowAddImg = true;
    }
  },
  methods: {
    onAddImg(event) {
      if(event.target.files.length < 1){
        return;
      }

      if(!/\.(jpg|jpeg|png)$/.test(event.target.files[0].name.toLowerCase())){
        event.target.value = "";
        this.$layer.msg("图片类型仅支持jpg、jpeg和png格式");
        return;
      }

      let img = event.target.files[0];
      let that = this;
      let reader = new FileReader();
      reader.readAsDataURL(img);
      reader.onload = function() {
        that.$refs.img.src = reader.result;
        that.manufacturerImg = img;
        that.isShowAddImg = false;
        event.target.value = null;
      };
      reader.onerror = function() {
        this.onDelImg();
        that.$layer.msg("读取文件失败，请重试");
      };
    },
    onDelImg() {
      this.manufacturerDiagramInfo.img = null;
      this.manufacturerImg = null;
      this.$refs.img.src = "";
      this.isShowAddImg = true;
    },
    onShowBigImgDiv(event) {
      this.$refs.bigImg.src = event.target.src;
      this.isShowBigImg = true;
    },
    onHideBigImgDiv() {
      this.isShowBigImg = false;
    },
    onCancel() {
      this.$layer.close(this.layerid);
    },
    onSave() {
      if(this.manufacturerDiagramInfo.img == null && this.manufacturerImg == null){
        this.setTipsMsg("请选择图片");
        return;
      }

      if(this.manufacturerImg == null){
        this.setTipsMsg("请选择图片");
        return;
      }

      let formData = new FormData();
      formData.append("machineryId", this.manufacturerDiagramInfo.id);
      formData.append("file", this.manufacturerImg);
      postManufacturerDiagramEdit(formData)
        .then(() => {
          this.refreshData();
          this.$layer.close(this.layerid);
          this.$layer.msg("操作成功");
        })
        .catch(error => {
          if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
            this.$layer.msg(error.data.msg);
          } else {
            this.$layer.msg("操作失败");
          }
        });
    },
    setTipsMsg(msg) {
      this.tipMsg = msg;
      clearTimeout(this.timeCounter);
      this.timeCounter = setTimeout(() => {
        this.tipMsg = "";
      }, 3000);
    }
  }
}
</script>

<style lang="less" scoped>
.layer-page-div {
  width: 100%;
  font-size: 14px;
  color: #2c3e50;
  .big-img-div {
    position: absolute;
    width: 100%;
    height: calc(100% - 42px);
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;
    img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
      align-items: center;
    }
  }
  .layer-content-div {
    width: 90%;
    margin: 10px auto 0 auto;
    .item-div {
      display: flex;
      align-items: center;
      margin-top: 10px;
      .item-name-div {
        width: 100px;
        text-align: right;
      }
      .preview-img-div {
        width: 64px;
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        img {
          width: auto;
          height: auto;
          max-width: 100%;
          max-height: 100%;
          vertical-align: middle;
        }
        .del-img {
          width: 16px;
          height: 16px;
          position: absolute;
          top: 0;
          right: 0;
        }
      }
    }
    .poster-div {
      margin-top: 30px;
    }
    .err-tips {
      margin-left: 5px;
    }
    .operate-div {
      margin-top: 20px;
      input {
        width: 120px;
        height: 35px;
        color: white;
        font-size: 16px;
        border: none;
        background: #4676c8;
        margin-top: 10px;
      }
      .save-input {
        margin-left: 5px;
      }
      .tips-div {
        width: 100%;
        height: 25px;
        margin-top: 10px;
      }
    }
  }
}
</style>